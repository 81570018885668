<template>
  <div class="IntroSection" id="IntroSection">
    <div class="text-part">
      <div class="partA">Hi, my name is</div>

      <div class="partB">Noureddin Hariri</div>
      <div class="partC">I build web things.</div>
      <div class="partD">
        I’m a software engineer specializing in building (and occasionally
        designing) exceptional digital experiences. Currently, I’m focused on
        building accessible, human-centered products
      </div>
      <div class="partE">check out my projects</div>
    </div>
    <div class="svg-part">
      <Section1svg></Section1svg>
    </div>
  </div>
</template>

<script>
import Section1svg from "./svgs/section1svg.vue";

export default { components: { Section1svg } };
</script>
<style lang="scss" scoped>
@import "../variables.scss";
.IntroSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10vh;
  height: 90vh;
  font-family: poppins;
  .text-part {
    width: 60%;
    display: flex;
    flex-direction: column;

    .partA {
      color: $pn-secondry-color;
      font-family: "Fira Code", monospace;
    }
    .partB {
      color: $pn-main-color;
      font-family: poppins;
      font-weight: 500;
      font-size: 5vw;
    }
    .partC {
      color: $pn-third-color;
      font-family: poppins;
      font-weight: 500;
      font-size: 5vw;
    }
    .partD {
      font-weight: 300;
      text-align: justify;
      font-size: 0.8rem;
      width: 90%;
    }

    .partE {
      cursor: pointer;
      margin-top: 2rem;
      width: fit-content;
      padding: 20px 20px;
      border: $pn-secondry-color 2px solid;
      color: $pn-secondry-color;
      font-weight: 300;
      font-family: "Fira Code", monospace;
      font-size: 0.9rem;
      &:hover {
        background-color: #ce4b4b3a;
      }
    }
  }
  .svg-part {
    width: 30%;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    .text-part {
      width: 100%;

      .partB {
        font-size: 2rem;
      }
      .partC {
        font-size: 2rem;
      }
      .partD {
        font-size: 0.8rem;
        width: 100%;
      }
    }
    .svg-part {
      width: 80%;
    }
  }
}
</style>
