<template>
  <NavBtn @click="toggleNavItems"></NavBtn>

  <div class="nav-items" :class="navItemsStatus">
    <a class="link" href="#AboutSection" @click="toggleNavItems">
      <div class="number">01.</div>
      <div class="title">About</div>
    </a>
    <a class="link" href="#SkillsSection" @click="toggleNavItems">
      <div class="number">02.</div>
      <div class="title">Skills</div>
    </a>
    <a class="link" href="#ProjectsSection" @click="toggleNavItems">
      <div class="number">03.</div>
      <div class="title">Projects</div>
    </a>
    <a class="link" href="#ContactSection" @click="toggleNavItems">
      <div class="number">04.</div>
      <div class="title">Contact</div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
.nav-items {
  width: 100%;
  //   height: 10rem;
  position: absolute;
  background-color: #0c192fdb;

  top: 100%;
  left: 0;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  visibility: hidden;
  z-index: -1;
  .link {
    display: flex;
    text-decoration: none;

    cursor: pointer;
    .number {
      color: #ce4b4b;
    }
    .title {
      color: #ccd6f6;
    }
    &:hover {
      .title {
        color: #ce4b4b;
      }
    }
  }
  //   a:nth-child(4) {
  //     width: 100%;
  //     padding: 1rem 0;
  //     text-align: center;
  //     background-color: #ffcec7;
  //     color: black;
  //   }
}
// in Mobile view -nav-items - closed
.nav-items-closed {
  height: 0;
  visibility: hidden;
  opacity: 0;
}
// in Mobile view -nav-items - opened
.nav-items-opened {
  height: 10rem;
  visibility: visible;
  opacity: 1;
}
</style>

<script>
import NavBtn from "./navBtn.vue";
export default {
  data() {
    return {
      // to determine weather or not the button is clicked
      isNavItems: false,

      window: {
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    toggleNavItems() {
      this.isNavItems = !this.isNavItems;
    },
  },
  computed: {
    navItemsStatus() {
      return {
        "nav-items-closed": !this.isNavItems,
        "nav-items-opened": this.isNavItems,
      };
    },
  },
  //   data() {
  //     return {
  //       window: {
  //         width: 0,
  //         height: 0,
  //       },
  //     };
  //   },
  //   computed: {
  //     isMobileView() {
  //       return this.window.width < 900;
  //     },
  //   },
  //   methods: {
  //     handleResize() {
  //       this.window.width = window.innerWidth;
  //       this.window.height = window.innerHeight;
  //     },
  //   },
  //   created() {
  //     window.addEventListener("resize", this.handleResize);
  //     this.handleResize();
  //   },
  //   destroyed() {
  //     window.removeEventListener("resize", this.handleResize);
  //   },
  //   created() {
  //     var prevScrollpos = window.pageYOffset;
  //     window.onscroll = function () {
  //       var currentScrollPos = window.pageYOffset;
  //       if (prevScrollpos > currentScrollPos) {
  //         document.getElementById("pn-navbar").style.top = "0";
  //       } else {
  //         document.getElementById("pn-navbar").style.top = "-100px";
  //       }
  //       prevScrollpos = currentScrollPos;
  //     };
  //   },
  components: { NavBtn },
};
</script>
