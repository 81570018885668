<template>
  <div class="projects-tabs">
    <div class="projects-list">
      <div class="line-c">
        <div class="long-line"></div>
        <div
          :style="{
            top: shortLineStartpoint + '%',
            height: shortLineLength + '%',
          }"
          class="short-line"
        ></div>
      </div>
      <div class="projects-titles">
        <div
          class="project-title"
          v-for="(project, index) in projects"
          :key="index"
          @click="selectProject(index)"
          :class="selectedProjectClass(index)"
        >
          #Project-{{ index + 1 }}
        </div>
      </div>
    </div>
    <div class="project-info">
      <h4 class="project-name">
        {{ selectedProjectInfo.name }}
        <span class="project-type">{{ selectedProjectInfo.type }}</span>
      </h4>
      <p class="project-description">
        {{ selectedProjectInfo.description }}
      </p>
      <div class="links">
        <a
          class="githubSvg"
          :href="selectedProjectInfo.githubLink"
          target="_blank"
        >
          <GithubSvg></GithubSvg>
        </a>
        <a class="githubSvg" :href="selectedProjectInfo.liveLink">
          <LiveIcon></LiveIcon>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../variables.scss";
.projects-tabs {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  gap: 1rem;
  //   background: green;
  .projects-list,
  .project-info {
    // width: 50%;
  }
  .projects-list {
    display: flex;
    height: fit-content;
    width: fit-content;
    // gap: 2rem;
    .line-c {
      position: relative;
      //   height: 400px;
      display: flex;
      .long-line {
        border-left: 1px #8892b0 solid;
        height: 100%;
      }
      .short-line {
        //Depending on the items count, the hight must be decided by programttically
        // height: ;
        border-left: 3px $pn-secondry-color solid;
        position: absolute;
        left: 0;
        transition: all 0.2s;
        // top: 25%;
      }
    }

    .projects-titles {
      color: $pn-third-color;
      display: flex;
      flex-flow: column;
      font-weight: 300;
      .project-title {
        cursor: pointer;
        padding: 0.5rem 2rem;
        transition: background 0.2s;
        white-space: nowrap;
        &:hover {
          font-weight: 400;
          color: $pn-secondry-color;
          background: rgba(255, 255, 255, 0.09);
        }
      }

      //Conditional Calss for the selected Project title
      .activeProject {
        font-weight: 400;
        color: $pn-secondry-color;
        background: rgba(255, 255, 255, 0.09);
      }
    }
  }
  .project-info {
    .project-name {
      .project-type {
        font-size: 0.8em;
        color: $pn-secondry-color;
      }
    }
    display: flex;
    flex-direction: column;
    // width: 70%;
    gap: 2rem;
    .project-description {
      text-align: justify;
    }
    .links {
      display: flex;
      gap: 2rem;
      .githubSvg {
        display: block;
        max-width: 2rem;
      }
    }
  }
  @media (max-width: 600px) {
    .projects-titles {
      .project-title {
        cursor: pointer;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        transition: background 0.2s;
        &:hover {
          font-weight: 400;
          color: $pn-secondry-color;
          background: rgba(255, 255, 255, 0.09);
        }
      }

      //Conditional Calss for the selected Project title
    }
    .project-info {
      font-size: 0.8rem;
      .project-description {
        text-align: justify;
      }
      .links {
        display: flex;
        gap: 2rem;
        .githubSvg {
          display: block;
          max-width: 2rem;
        }
      }
    }
  }
}
</style>

<script>
import GithubSvg from "../svgs/githubSvg.vue";
import LiveIcon from "../svgs/liveIcon.vue";

export default {
  methods: {
    selectProject(index) {
      this.selectedProjectIndex = index;
    },
    selectedProjectClass(index) {
      return {
        activeProject: this.selectedProjectIndex === index,
      };
    },
  },
  computed: {
    shortLineStartpoint() {
      let projectsCount = this.projects.length;
      let oneProjectProportion = 100 / projectsCount;
      //   console.log(oneProjectProportion);
      return (
        (~~this.selectedProjectIndex + 1) * oneProjectProportion -
        oneProjectProportion
      );
    },
    shortLineLength() {
      let projectsCount = this.projects.length;
      let oneProjectProportion = 100 / projectsCount;
      return oneProjectProportion;
    },
    // to get the info of the selected project to be displayed in the infor part
    selectedProjectInfo() {
      return this.projects[this.selectedProjectIndex];
    },
  },
  data() {
    return {
      selectedProjectIndex: 0,
      projects: [
        // {
        //   name: "Terma",
        //   type: "Front End",
        //   description:
        //     "Flash Cards App, help the user to create own’s lists of new terms to memorize, with a varity of options to learn and practice.",
        //   githubLink: "https://github.com/iNo0r/terms-app",
        //   liveLink: "https://test-auth-fc7e4.web.app/",
        // },
        {
          name: "Phones Retailer",
          type: "Front End",
          description:
            "A busines website, sells mobile phones and uses clean lines and colorful, professional images to make a cohesive website.",
          githubLink:
            "https://github.com/iNo0r/phones-installment-demo-website",
          liveLink: "https://phones-installment.web.app",
        },
        {
          name: "Match The Boxes",
          type: "Front End",
          description:
            "One of the most effective memory games. All you have to do is to remember where each box located and match them.",
          githubLink: "https://github.com/iNo0r/cards-memory",
          liveLink: "https://match-the-box.web.app/",
        },
        {
          name: "Weather App",
          type: "Front End",
          description:
            "Not only a regular weather App, Based on your public IP , it shows weatehr data, location and a set of photos realted to the locaiton.",
          githubLink: "https://github.com/iNo0r/simple_weather_app",
          liveLink: "https://weather-app-5532e.web.app/",
        },
      ],
    };
  },
  components: { GithubSvg, LiveIcon },
};
</script>
