<template>
  <div class="ContactSection" id="ContactSection">
    <div class="text-part">
      <div class="section-header">
        <div class="section-title">
          <div class="number">04.</div>
          <div class="title">What is next?</div>
        </div>
        <div class="pn-line"></div>
      </div>
      <!-- <div class="partA">Hi, my name is</div> -->

      <div class="partB">Get In Touch</div>
      <div class="partD">
        I’m currently looking for new opportunities, my inbox is always open.
        Whether you have a question or just want to say hi, I’ll try my best to
        get back to you!
      </div>
      <div class="partE">
        <a href="mailto: noureddin.hariri@gmail.com">Contact Me</a>
      </div>
    </div>
    <div class="svg-part">
      <Section5svg></Section5svg>
    </div>
  </div>
</template>

<script>
import Section1svg from "./svgs/section1svg.vue";
import Section5svg from "./svgs/section5svg.vue";

export default { components: { Section1svg, Section5svg } };
</script>
<style lang="scss" scoped>
@import "../variables.scss";
.ContactSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10vh;
  height: 90vh;
  font-family: poppins;
  .text-part {
    width: 60%;
    display: flex;
    flex-direction: column;
    .section-header {
      font-family: "Fira Code", monospace;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      .section-title {
        display: flex;
        gap: 1rem;

        .number {
          color: #ce4b4b;
        }
        .title {
          color: #ccd6f6;
          white-space: nowrap;
        }
      }
      .pn-line {
        border-bottom: #ccd6f62a 1px solid;
        width: 100%;
      }
    }

    .partA {
      color: $pn-secondry-color;
      font-family: "Fira Code", monospace;
    }
    .partB {
      color: $pn-main-color;
      font-family: poppins;
      font-weight: 500;
      font-size: 5vw;
    }
    .partC {
      color: $pn-third-color;
      font-family: poppins;
      font-weight: 500;
      font-size: 5vw;
    }
    .partD {
      font-weight: 300;
      text-align: justify;
      font-size: 0.8rem;
      width: 90%;
    }

    .partE {
      cursor: pointer;
      margin-top: 2rem;
      width: fit-content;
      padding: 20px 20px;
      border: $pn-secondry-color 2px solid;
      color: $pn-secondry-color;
      font-weight: 300;
      font-family: "Fira Code", monospace;
      font-size: 0.9rem;
      a {
        text-decoration: none;
        color: $pn-secondry-color;
      }
      &:hover {
        background-color: #ce4b4b3a;
      }
    }
  }
  .svg-part {
    width: 30%;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    .text-part {
      width: 100%;

      .partB {
        font-size: 2rem;
      }
      .partC {
        font-size: 2rem;
      }
      .partD {
        font-size: 0.8rem;
        width: 100%;
      }
    }
    .svg-part {
      width: 80%;
    }
  }
}
</style>
