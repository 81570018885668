<template>
  <div class="app-layout">
    <div class="my-nav">
      <Navbar></Navbar>
    </div>

    <div class="sections">
      <IntroSection></IntroSection>
      <AboutSection></AboutSection>
      <SkillsSection></SkillsSection>
      <ProjectsSection></ProjectsSection>
      <ContactSection></ContactSection>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import AboutSection from "./components/AboutSection.vue";
import SkillsSection from "./components/SkillsSection.vue";
import IntroSection from "./components/IntroSection.vue";
import ProjectsSection from "./components/ProjectsSection.vue";
import ContactSection from "./components/ContactSection.vue";
export default {
  components: {
    Navbar,
    AboutSection,
    SkillsSection,
    IntroSection,
    ProjectsSection,
    ContactSection,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500&family=Major+Mono+Display&family=Poppins:wght@100;300;400;500;700&family=Qahiri&family=Quantico:wght@400;700&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #0c192f;
  color: #ccccdd;
}

.app-layout {
  .sections {
    padding: 0 180px;
    @media (max-width: 1200px) {
      padding: 0 10vw;
    }
    // @media (max-width: 800px) {
    //   padding: 0 60px;
    // }
    // @media (max-width: 500px) {
    //   padding: 0 20px;
    // }
  }
}

// .may/
</style>
