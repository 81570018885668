<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 9L1 1.5V16.5L9 9Z" stroke="#CE4B4B" />
  </svg>
</template>
