<template>
  <div class="SkillsSection" id="SkillsSection">
    <div class="text-part">
      <div class="section-header">
        <div class="section-title">
          <div class="number">02.</div>
          <div class="title">Skills</div>
        </div>
        <div class="pn-line"></div>
      </div>
      <div class="partA">
        <div class="skill">
          <div class="arrow">
            <ArrowSvg></ArrowSvg>
          </div>
          <div class="skill-title">HTML, CSS & JS</div>
        </div>
        <div class="skill">
          <div class="arrow">
            <ArrowSvg></ArrowSvg>
          </div>
          <div class="skill-title">Vue js</div>
        </div>
        <div class="skill">
          <div class="arrow">
            <ArrowSvg></ArrowSvg>
          </div>
          <div class="skill-title">Node js</div>
        </div>
        <div class="skill">
          <div class="arrow">
            <ArrowSvg></ArrowSvg>
          </div>
          <div class="skill-title">Figma</div>
        </div>
        <div class="skill">
          <div class="arrow">
            <ArrowSvg></ArrowSvg>
          </div>
          <div class="skill-title">GIT</div>
        </div>
        <div class="skill">
          <div class="arrow">
            <ArrowSvg></ArrowSvg>
          </div>
          <div class="skill-title">SASS</div>
        </div>
        <div class="skill">
          <div class="arrow">
            <ArrowSvg></ArrowSvg>
          </div>
          <div class="skill-title">Firebase</div>
        </div>
      </div>
    </div>
    <div class="svg-part">
      <Section3svg></Section3svg>
    </div>
  </div>
</template>

<script>
import Section3svg from "./svgs/section3svg.vue";
import ArrowSvg from "./svgs/arrowSvg.vue";
export default { components: { Section3svg, ArrowSvg } };
</script>
<style lang="scss" scoped>
@import "../variables.scss";
.SkillsSection {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 80vh;
  font-family: poppins;
  .text-part {
    width: 50%;
    display: flex;
    flex-direction: column;

    .section-header {
      font-family: "Fira Code", monospace;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      .section-title {
        display: flex;
        gap: 1rem;
        .number {
          color: #ce4b4b;
        }
        .title {
          color: #ccd6f6;
        }
      }
      .pn-line {
        border-bottom: #ccd6f62a 1px solid;
        width: 100%;
      }
    }

    .partA {
      color: $pn-main-color;
      font-family: poppins, monospace;
      font-weight: 300;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      //   background: green;
      justify-content: space-between;
      gap: 1rem;
      .skill {
        width: 40%;
        // background: red;
        display: flex;
        gap: 5%;
        .arrow {
          width: 0.5rem;
        }
      }
    }
  }
  .svg-part {
    width: 30%;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    .text-part {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .svg-part {
      width: 80%;
      order: 2;
    }
  }
}
</style>
