<template>
  <div class="AboutSection" id="AboutSection">
    <div class="svg-part">
      <Section2svg></Section2svg>
    </div>
    <div class="text-part">
      <div class="section-header">
        <div class="section-title">
          <div class="number">01.</div>
          <div class="title">About</div>
        </div>
        <div class="pn-line"></div>
      </div>
      <div class="partA">
        Hello! My name is Noureddin and I enjoy creating things that live on the
        internet. My interest in web development started back in 2018 when I
        decided to try editing custom Tumblr themes — turns out hacking together
        a custom reblog button taught me a lot about HTML & CSS!
      </div>
    </div>
  </div>
</template>

<script>
import Section2svg from "./svgs/section2svg.vue";
export default { components: { Section2svg } };
</script>
<style lang="scss" scoped>
@import "../variables.scss";
.AboutSection {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 80vh;
  font-family: poppins;
  .text-part {
    width: 50%;
    display: flex;
    flex-direction: column;

    .section-header {
      font-family: "Fira Code", monospace;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      .section-title {
        display: flex;
        gap: 1rem;
        .number {
          color: #ce4b4b;
        }
        .title {
          color: #ccd6f6;
        }
      }
      .pn-line {
        border-bottom: #ccd6f62a 1px solid;
        width: 100%;
      }
    }

    .partA {
      color: $pn-third-color;
      font-family: poppins, monospace;
      font-weight: 300;
    }
  }
  .svg-part {
    width: 30%;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    .text-part {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .svg-part {
      width: 80%;
      order: 2;
    }
  }
}
</style>
