<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 67 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="app-logo"
  >
    <path
      class="inner-letters"
      d="M19.9868 45.1H29.2868L18.5468 26.53V46H17.6468V25H18.6968L29.9168 44.41V25H30.8168V46H19.9868V45.1ZM49.3483 46H48.4483V35.5H37.6483V46H36.7483V25H37.6483V34.6H48.4483V25H49.3483V46Z"
      fill="#FB8181"
    />
    <rect
      x="0.707107"
      y="33.2893"
      width="46.0782"
      height="46.0782"
      transform="rotate(-45 0.707107 33.2893)"
      stroke="#FB8181"
    />
  </svg>
</template>

<style lang="scss">
.app-logo:hover {
  cursor: pointer;
  .inner-letters {
    transition: all 0.5s;
    fill: white;
  }
}
</style>
