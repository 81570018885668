<template>
  <div class="ProjectsSection" id="ProjectsSection">
    <div class="svg-part">
      <Section4svg></Section4svg>
    </div>
    <div class="text-part">
      <div class="section-header">
        <div class="section-title">
          <div class="number">04.</div>
          <div class="title">Projects</div>
        </div>
        <div class="pn-line"></div>
      </div>
      <div class="partA">
        <ProjectsTabs></ProjectsTabs>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowSvg from "./svgs/arrowSvg.vue";
import Section4svg from "./svgs/section4svg.vue";
import ProjectsTabs from "./ProjectsSection/ProjectsTabs.vue";

export default { components: { ArrowSvg, Section4svg, ProjectsTabs } };
</script>
<style lang="scss" scoped>
@import "../variables.scss";
.ProjectsSection {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 80vh;
  font-family: poppins;
  .text-part {
    width: 70%;
    display: flex;
    flex-direction: column;

    .section-header {
      font-family: "Fira Code", monospace;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      .section-title {
        display: flex;
        gap: 1rem;
        .number {
          color: #ce4b4b;
        }
        .title {
          color: #ccd6f6;
        }
      }
      .pn-line {
        border-bottom: #ccd6f62a 1px solid;
        width: 100%;
      }
    }

    .partA {
      color: $pn-main-color;
      font-family: poppins, monospace;
      font-weight: 300;

      width: 100%;
    }
  }
  .svg-part {
    width: 20%;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    .text-part {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .svg-part {
      width: 40%;
      transform: rotate(-90deg);
      order: 2;
      // max-height: 20rem;
    }
  }
}
</style>
