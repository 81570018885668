<template>
  <div class="pn-navbar" id="pn-navbar">
    <div class="app-logo">
      <AppLogo></AppLogo>
    </div>
    <MobileLinks v-if="isMobileView"></MobileLinks>
    <div class="desktop-links" v-else>
      <a class="link" href="#AboutSection">
        <div class="number">01.</div>
        <div class="title">About</div>
      </a>
      <a class="link" href="#SkillsSection">
        <div class="number">02.</div>
        <div class="title">Skills</div>
      </a>
      <a class="link" href="#ProjectsSection">
        <div class="number">03.</div>
        <div class="title">Projects</div>
      </a>
      <a class="link" href="#ContactSection">
        <div class="number">04.</div>
        <div class="title">Contact</div>
      </a>
    </div>
  </div>
</template>

<script>
import AppLogo from "./svgs/appLogo.vue";
import MobileLinks from "./Navbar/mobileLinks.vue";
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    isMobileView() {
      return this.window.width <= 600;
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    //  this to hide the bar on scrolling
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("pn-navbar").style.top = "0";
      } else {
        document.getElementById("pn-navbar").style.top = "-500px";
      }
      prevScrollpos = currentScrollPos;
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  // created() {
  //   var prevScrollpos = window.pageYOffset;
  //   window.onscroll = function () {
  //     var currentScrollPos = window.pageYOffset;
  //     if (prevScrollpos > currentScrollPos) {
  //       document.getElementById("pn-navbar").style.top = "0";
  //     } else {
  //       document.getElementById("pn-navbar").style.top = "-100px";
  //     }
  //     prevScrollpos = currentScrollPos;
  //   };
  // },
  components: { AppLogo, MobileLinks },
};
</script>

<style lang="scss" scoped>
.pn-navbar {
  background-color: #0c192f92;
  backdrop-filter: blur(5px);
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  width: 100%; /* Full width */
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  font-family: "Fira Code", monospace;
  font-weight: 300;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 80px;
  //   background: green;

  .app-logo {
    width: 4rem;
  }
  .desktop-links {
    display: flex;
    align-items: center;
    gap: 2rem;
    .link {
      display: flex;
      text-decoration: none;
      cursor: pointer;
      .number {
        color: #ce4b4b;
      }
      .title {
        color: #ccd6f6;
      }
      &:hover {
        .title {
          color: #ce4b4b;
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 20px 30px;
  }
}
</style>
